import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Kaspersky Internet Security 2017",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2017/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_Kaspersky17.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_Kaspersky17.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Kaspersky 2017' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/Kaspersky_2017/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2017/' crumbLabel="Kaspersky" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "allow-javascript-for-your-cameras-webui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#allow-javascript-for-your-cameras-webui",
        "aria-label": "allow javascript for your cameras webui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Allow JavaScript for your Cameras WebUI`}</h2>
    <p>{`In some cases, it can be that the virus scanner Kaspersky is blocking the user interface of your camera. In this case you will need to deactivate a function in Kaspersky so it does not block the camera any more.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d9bb55d2e29c81ab866e433d52bb13da/2215f/Kaspersky_2017_Step_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAADHElEQVQ4y2WS3WscVRjGDylo0QvBm15JaYRiULGC2Lv6B4jXXvsH6J13ChYFseKNGKpsumla7BKTYo1a/KA2hkJrIa1J4252NrOzn9npZHdnduf78yczm0029AwvL+9znvc573PmiGZbRWk2aLSa1Fst6u02ardH37TRTZtWr8ddWeKBXKHX19EtG90O0C0XfejQMyy6usnQcthq9hCPrk5zL3eStdxL3Jk7ze2Lp1i/PE1laQZpaYbS4mk2r02zVXgRafllpB9O0iots2eGqK6N6rp0XJee57BV6yAeXBSszk7RXj1B994M5Z8Fa7NTyD++SmXlDeQbryHfeAX5pzOUF1+gmBeU/3iHTqlG//wX6J99hf7x5xi5BTbqjxGb889w88Ix1heeprh4grVvprj19bPUb76JtHyKcuE5tgvHKReOU7pyjOIlgfT7WXb/voN65hx7595Ge/0t9t59j42mhvg3J1j9UnD906f4/sJZrn8iuDsr2Fl6nuKCoHhlIq4K/rskUP75iN2uR6MiUS9XqG9X6DSabCgdxONSHlOeYyBfxqgtYSp5BvIcfek7jJ05jGqeYXUeszqPIecxdnJYwza2F+FGERHgRDFBFKJoA0TIaKW5oVqM68mlhtAOnsRd16dabxMEoy516CM83wMijIHBZrGMbVuQRCRxmEUYeDzSGlT7KlHo7+NxJmBaDts7NRzPz+pdw0X4QZQVvu/jBz5BMBolSZIsa6aBpO1S7ar0bfNgL45jTNNEN3RMy8rwzsBD+OFIME4SLNs9sJM2jUVTjjdx0BgPwhBjYBHsa2SCnueN7sPzKUpKRppsTL9iRUFSmk8IppYfbklZzgRTy16llNIwBkPqzRa27RyxTAyB6RCl0ycc2UstN1ptTMs+FPRXroFjZdz0XiYncMKAvzSFW5rCn1qVNa1GuP9DxpyRBw4tBysFsIYZOBaL98ld1+bD9d94//4vfHD/V85v3CaIosN3kxzNairYKnxLZ/th9kCTKMrEsoiT9ISD3jAMGboOA8/BcG327CGW79F3LDTbRHdN5O6Q/wGKVioas6rIewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9bb55d2e29c81ab866e433d52bb13da/e4706/Kaspersky_2017_Step_01.avif 230w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/d1af7/Kaspersky_2017_Step_01.avif 460w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/7f308/Kaspersky_2017_Step_01.avif 920w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/e1c99/Kaspersky_2017_Step_01.avif 1380w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/b48a2/Kaspersky_2017_Step_01.avif 1548w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d9bb55d2e29c81ab866e433d52bb13da/a0b58/Kaspersky_2017_Step_01.webp 230w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/bc10c/Kaspersky_2017_Step_01.webp 460w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/966d8/Kaspersky_2017_Step_01.webp 920w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/445df/Kaspersky_2017_Step_01.webp 1380w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/10263/Kaspersky_2017_Step_01.webp 1548w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9bb55d2e29c81ab866e433d52bb13da/81c8e/Kaspersky_2017_Step_01.png 230w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/08a84/Kaspersky_2017_Step_01.png 460w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/c0255/Kaspersky_2017_Step_01.png 920w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/b1001/Kaspersky_2017_Step_01.png 1380w", "/en/static/d9bb55d2e29c81ab866e433d52bb13da/2215f/Kaspersky_2017_Step_01.png 1548w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d9bb55d2e29c81ab866e433d52bb13da/c0255/Kaspersky_2017_Step_01.png",
              "alt": "Kaspersky 2017",
              "title": "Kaspersky 2017",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open Kaspersky by clicking the Kaspersky symbol on the right side of the windows task bar. Another way is to open the start menu and to choose the program directly. Once opened please click on Settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6f5148fd9fac954f59bcd962e60cbbae/61946/Kaspersky_2017_Step_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAACdUlEQVQ4y3WUy27TQBiF/XI8ABveAYkVqNsuEBsqwY4VQhUSEkKVkGDDQ0CvaWia2OPx3Z6LYyeOHScZHzRjGqW3kY5nbNnfnP8ytkqZI8tScM7AOYcQAnVd42ZUywbDJICdhFCrlXnWdZ2+mrVSCuv12qyFlLASkRtYEIbwgwCLHdjNx6pTRug6c/+Q9ChmM1jTxQJVu+x32mywUQosLxAyASfLkIgpeF6CT0uwvASbPqC8hCznGFMfFq8qlMvmlqOISdhhgt+2jWsvBAkTRDw38Jjn96TfT2WB4YTA4vM5FqvWOFv9d0ijFCMa4NylOCEEvx0bZ4SAhqn5WIN1qvq53yTLS/y1XVjtpjO52R1eKoyzU9fFpefhTINdgis/QJiJB932QALr89En7H/9gHdHH/H++yGOfn5B5hCM4wwnxME5pRi6va79ACSIEaT8QeBQA/cP9vDs4BWevnmBJ6+fY+/tS+SjEcYpxylxTKhOJhGVNbJZjUQWW9gudAtE0wDtEli1vdZr+AnvQyYEI53wXz/Avx2CnR4jiRLEokBypzBbYKs606JqR37CDPBc5zCI4A8GYJcXSKIYCROIdTEec9iuN6brd5s0TDnsMDYV1iF7mQRlee9MFlvIPeBkC8S2200fZgJjP8IZ1VX2TXH+ODYm2iHPkYoCqYaJ/FbbGODqEeAkiE0OB9TDhUtN+wxcDyM/hJsy0IzdaewSg7HTO1Q74ep1zCRInOJYV5m6JpcGTCkufA9XcQySMXPkMlkYyaLClUNhhbMSrJ6bE2KAqtvOaqOMuo3CvKnBqxlmTY2qbSDrufkHTOsKfDGHbBaIhcA/IT5eL//3qJsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f5148fd9fac954f59bcd962e60cbbae/e4706/Kaspersky_2017_Step_02.avif 230w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/d1af7/Kaspersky_2017_Step_02.avif 460w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/7f308/Kaspersky_2017_Step_02.avif 920w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/e1c99/Kaspersky_2017_Step_02.avif 1380w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/8a8ff/Kaspersky_2017_Step_02.avif 1546w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6f5148fd9fac954f59bcd962e60cbbae/a0b58/Kaspersky_2017_Step_02.webp 230w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/bc10c/Kaspersky_2017_Step_02.webp 460w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/966d8/Kaspersky_2017_Step_02.webp 920w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/445df/Kaspersky_2017_Step_02.webp 1380w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/87ef7/Kaspersky_2017_Step_02.webp 1546w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f5148fd9fac954f59bcd962e60cbbae/81c8e/Kaspersky_2017_Step_02.png 230w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/08a84/Kaspersky_2017_Step_02.png 460w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/c0255/Kaspersky_2017_Step_02.png 920w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/b1001/Kaspersky_2017_Step_02.png 1380w", "/en/static/6f5148fd9fac954f59bcd962e60cbbae/61946/Kaspersky_2017_Step_02.png 1546w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6f5148fd9fac954f59bcd962e60cbbae/c0255/Kaspersky_2017_Step_02.png",
              "alt": "Kaspersky 2017",
              "title": "Kaspersky 2017",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Go to the Advanced section and click on Network.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0aaa14d4139b76bb29480b4c0db6381f/c0566/Kaspersky_2017_Step_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAACSElEQVQ4y32T6Y6bMBSFeX+pD9A36L/+65++QquZNAkJi20W4wUIAZKZyQI5lW8SZlGnSJ+MZTi6555rr6trFErBWgvjMAZd3+N8PmMYBrRPe8xkgkhlOL28YBhGOjufBzo/nU44HA4YxxGmLOFVzZaEnKjSmpBS4un5Ge4ZLyOGcaQfPnsulwutbdfDSwqNRCqIvECqDL3zrICQCrm2qLb9G7p/UjYdmn6PMMngsVRiFcRYxQJ+xLFiAgFPsQwZ/JgjNxV0vYWqmk8pyg3MpsUiYvCiXGFelFhEHMuIYRHGmK8jCKmR6ZKQtkZua8iqIfGPOFHbdFjGHB5/fED29QuCMMaap4hTSRWyrCDcnmUSYSYh7Gu1b8UmwYjDYzED//4N7OcPBBFDdrNYNR3spiUrE/+xPgkmukRoG6xYgpAnWLMEcSax0hrcVjB1e/3pg01z476fBAORwg9dKJwsu9UFMwsjarILxTXdQb00FZHZCqmtpv0k6MR+zeb4/WdJPCxW13XuY+avMfMDSnwZxghYcu1tKrHOJXx57TPP1WvKidRYx4LGxnGv0oWRFIbSJgoNbsqpn/aGe3eW3SzOQyeoDHwmSN2NTMAT+LFAIDLQ0BdmQpjy01Cc4CLk8NZS4kEIPKYpQqWhb4N6T+6OeVPNuxmst/S9vVfobM5WAVUk6AqqV5uEIlxrUsetDZmydE2VE9u02LQ7cuYV/RZq1+JwPOJwuHF8z/F4QrPvodoG9a5H3XfQXYN2v0O936Ha99i8PEFWJf4CDmVWqr9W1O0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0aaa14d4139b76bb29480b4c0db6381f/e4706/Kaspersky_2017_Step_03.avif 230w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/d1af7/Kaspersky_2017_Step_03.avif 460w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/7f308/Kaspersky_2017_Step_03.avif 920w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/e1c99/Kaspersky_2017_Step_03.avif 1380w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/255dd/Kaspersky_2017_Step_03.avif 1544w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0aaa14d4139b76bb29480b4c0db6381f/a0b58/Kaspersky_2017_Step_03.webp 230w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/bc10c/Kaspersky_2017_Step_03.webp 460w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/966d8/Kaspersky_2017_Step_03.webp 920w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/445df/Kaspersky_2017_Step_03.webp 1380w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/e33fd/Kaspersky_2017_Step_03.webp 1544w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0aaa14d4139b76bb29480b4c0db6381f/81c8e/Kaspersky_2017_Step_03.png 230w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/08a84/Kaspersky_2017_Step_03.png 460w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/c0255/Kaspersky_2017_Step_03.png 920w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/b1001/Kaspersky_2017_Step_03.png 1380w", "/en/static/0aaa14d4139b76bb29480b4c0db6381f/c0566/Kaspersky_2017_Step_03.png 1544w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0aaa14d4139b76bb29480b4c0db6381f/c0255/Kaspersky_2017_Step_03.png",
              "alt": "Kaspersky 2017",
              "title": "Kaspersky 2017",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Now uncheck the Kaspersky Script.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/0c1c2/Kaspersky_2017_Step_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC+UlEQVQ4y42TW28aVxSF5yemTVJztYEBDKZxbC52q0hJWxuwHSeqVCluwNycH9FKfYv6A9qXvuQhaUKAYZhhBjCDGeCrzhlkNVIjdUtL+7DZWmedvfYotjHgY6eDpmkSPU3DHo1wFwsWiwXW9YQ/2+9403nP3HFYLJe4rovrev/P53Nubm6Yuy7T6RRFHw4liSDtdLsSH9ptrqdTRKxYsVytWK1W/J9QiuVTjksnHJfKlMqnlE/OKK7z6dNznj3/8Rbn/4Gz8+ecnT+TuXz2FGUrqhIIbRIMb+ELhLjz5T2+uHtf5jt37/OVL8BGIMSGP7TOwVuI/kgsTlRNEFWTbEZiKInttCyIHw/3s9QbDSqXNWr1BvVGk3qzRaN1RUPkZotm60qidfWK6mWN3b0sqZ2v2cnskkztoMSTKdR4En8wTLFUhtWI+cxh6jhyyI7jMJvNcAQc55N5iXrh8BsERzrzgPh2yiOMxuJS/tFxUTYahkG3p6H1dXr9/i1ErdProRuG7LMsi/zBvwiTKRQhUxyEQmGMJDSHtDtdelpfQhB/7IgN6GFZtlwbEbZtf6pQEKqJbWLxBL5gmKNiybvZHklF+sCgrw/oDwz0oYVu2Uym3ihEjEYjcoUDBMctoTAkvBXl3oaPJ9/94DWOxwwtG8u2Jbl42rWhsxjZzCZjnNmN7DNNk71sXjotjFk/OU1sbcr3R0UWwHS+ZLYAx10ibFj+9Qez178w+v03lq9/xRqPMSfXdPQBucIhYlOEQlUSpneIxhOEIzEyu3tU6k1evLzkolqjWm9Sab6idlKk+qhA5fG31B4dcPGywovqJT9d/EzmwUO205m1y2mUSCJJIBojpKqEYyo+ubDe0grV/kAI32YUf0TFvxXDJ7KoB8PygxDPFAoFImoCRdyQTGfkTWJJxUz2cx7E2UOOrEReYj9bIJc/YF/2eud84ZDdvRzKW13jndFnPJl8FpPJBG1o8Leu0TUNuuaA9wMNfWjSMQ3a5oAPhs7AtvgHiLyCaFgHSRoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/e4706/Kaspersky_2017_Step_04.avif 230w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/d1af7/Kaspersky_2017_Step_04.avif 460w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/7f308/Kaspersky_2017_Step_04.avif 920w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/e1c99/Kaspersky_2017_Step_04.avif 1380w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/eec8b/Kaspersky_2017_Step_04.avif 1542w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/a0b58/Kaspersky_2017_Step_04.webp 230w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/bc10c/Kaspersky_2017_Step_04.webp 460w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/966d8/Kaspersky_2017_Step_04.webp 920w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/445df/Kaspersky_2017_Step_04.webp 1380w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/64634/Kaspersky_2017_Step_04.webp 1542w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/81c8e/Kaspersky_2017_Step_04.png 230w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/08a84/Kaspersky_2017_Step_04.png 460w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/c0255/Kaspersky_2017_Step_04.png 920w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/b1001/Kaspersky_2017_Step_04.png 1380w", "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/0c1c2/Kaspersky_2017_Step_04.png 1542w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/37d993cced89f80e6c7f2a0b4f9f85d1/c0255/Kaspersky_2017_Step_04.png",
              "alt": "Kaspersky 2017",
              "title": "Kaspersky 2017",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Confirm your settings and go back to your camera´s user interface and reload.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      